import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { getUserProducts, getUserFromToken, getProduct } from "../../utils/lib"
import { genHash } from "../../utils/index"

import { FiCheckCircle } from "react-icons/fi"
import Login from "../shared/login"
import {
  succeed,
  fail,
  loading,
  notLoading,
  accessValid,
  openLogin,
  update,
} from "./utils"
import Img from "gatsby-image"
import useGlobalState from "../../utils/useGlobalState"

const INITIAL = { loading: true, error: null, value: null }
const id = x => x
const noOp = id
//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================]
// Handles
// * Restricting access
// * Refered by bookshelf with access token
// * New login
// ============================

const Auth = ({ children }) => {
  // ======================
  // 🌍🌍 GLOBALSTATE 🌍🌍
  // ======================
  const [state, setState] = useGlobalState()
  // const [state, setState] = useAuthState({ hasAccess: false })
  // ======================
  // 🏠🏠 LOCAL STATE 🏠🏠
  // ======================
  const data = useStaticQuery(PASSWORD)
  const product_id = data?.wordpressAcfOptions?.options?.product_id
  const icon = data?.file?.childImageSharp?.fluid

  const [user, setUser] = useState(INITIAL)
  const [product, setProduct] = useState(INITIAL)
  const [message, setMessage] = useState(INITIAL)

  // > this could be grouped with the above
  //    check if value emptyString and use that as title
  const [login, setLogin] = useState({
    title: "Login",
    value: false,
    loading: false,
  })

  // ===================
  // 🧨🧨 EFFECTS 🧨🧨
  // ===================
  useEffect(() => (!accessValid(state) ? setLogin(openLogin) : noOp), [state])

  useEffect(() => {
    if (accessValid(state)) return noOp

    var search = window?.location?.search
    if (search && search.includes("?token=")) {
      setLogin(loading)
      var token = search.replace("?token=", "")
      getUserFromToken(token)
        .then(async user => {
          setUser(succeed(user))

          var product = await getProduct(product_id, token)
          setProduct(succeed(product))

          var products = await getUserProducts(token, user.id)

          var hasAccess = products.some(
            ({ product_id: pid }) => pid === product_id
          )

          var payload = {
            user,
            token,
            product,
            products,
            hasAccess: true,
            hash: genHash(),
          }
          if (!hasAccess) setMessage(fail("You do not have access"))
          setState(update(payload))
          setLogin(notLoading)
        })
        .catch(e => {
          //  🧤 🚨 CATCH 🚨 🧤
          const failed = updater => updater(fail())
          ;[setUser, setProduct].forEach(failed)

          setLogin(notLoading)
          var code = e?.response?.data?.code

          if (code === "rest_not_logged_in")
            setLogin(openLogin("Please Login (Custom message)"))
          else setMessage(fail(e.message))
        })
      //  🧤 🚨 END CATCH 🚨 🧤
    } else {
      // !(search && search.includes("?token="))
      setLogin(openLogin("Please Login to view this content"))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // ==================
  // 📺📺 RENDER 📺📺
  // ==================
  if (accessValid(state)) return children

  return (
    <Container>
      {login.value && !login.loading ? (
        <Login title={login.title} />
      ) : (
        <Box>
          <Img fluid={icon} />
          <h1>Site Verification</h1>
          <Item label="User" {...user} value={user?.value?.name} />
          <Item label="Product" {...product} value={product?.value?.id} />
          {[product, user].every(({ loading }) => !loading) && (
            <Item label="Info" {...message} value={message?.value} />
          )}
        </Box>
      )}
    </Container>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Item = ({ label, msg = "fetching...", loading, error, value }) => {
  return (
    <Load>
      <span>{label}:</span>
      {loading ? (
        <>
          <Spinner /> {msg}
        </>
      ) : error ? (
        <Error className="err">{error}</Error>
      ) : (
        <>
          <FiCheckCircle size="25px" /> {value}
        </>
      )}
    </Load>
  )
}

const Box = styled.div`
  border-radius: 6px;
  box-shadow: 0 1px 3px 0px #929292;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const Error = styled.span`
  margin: 0 10px;
  &.err {
    font-weight: 300;
    font-size: 16px;
  }
`

/**
 * height: 100vh;
 * width: 100vw;
 * display: flex;
 * align-items: center;
 * justify-content: center;
 * flex-direction: column;
 * font-family: Roboto;
 */
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
`

const animate = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  color: green;
  &:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    animation: ${animate} 1.2s linear infinite;
  }
`

export const Spin = Spinner

const Load = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  span {
    font-weight: 500;
    font-size: 20px;
  }
  svg {
    margin: 0 10px;
    color: #008773;
  }
  ${Spinner} {
    margin: 0 10px;
  }
`
const PASSWORD = graphql`
  {
    wordpressAcfOptions {
      options {
        product_id
      }
    }

    file(name: { eq: "secure" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Auth
