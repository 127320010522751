import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { getToken, getUserFromToken } from "../../../utils/lib"
import { graphql, useStaticQuery } from "gatsby"
import { genHash } from "../../../utils"
import Img from "gatsby-image"
import { Spin } from "../../auth"
import axios from "axios"
import useGlobalState from "../../../utils/useGlobalState"

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Login = ({ title, plain, description, back = false }) => {
  const {
    wordpressAcfOptions: {
      options: {
        free,
        product_id,
        front_end_url,
        locked_out_message: { form_description, form_heading },
      },
    },
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(PASSWORD)

  // const [auth, setAuth] = useAuthState({ hasAccess: false })
  const [auth, setAuth] = useGlobalState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [link, setLink] = useState(false)
  const [state, setState] = useState({
    username: "",
    password: "",
  })
  const onChange = e => {
    var {
      target: { value, name },
    } = e
    e.persist()
    setState(p => ({ ...p, [name]: value }))
  }

  var [localAuth, setLocalAuth] = useState(auth)
  useEffect(() => {
    setAuth(localAuth)
  }, [localAuth]) // eslint-disable-line react-hooks/exhaustive-deps

  // LOGIN
  async function login() {
    setLoading(true)
    // setError()
    var token = await getToken(state.username, state.password).catch(e => {
      // show register

      setError(
        "A user with that username/password combination doesn't exist, please try again or register bellow"
      )

      setLoading(false)
    })
    if (!token) return setLoading(false)
    const user = await getUserFromToken(token).catch(e => {
      // error / register
      setError("Please try again or come back later")
      setLoading(false)
    })
    if (!user) return setLoading(false)

    var pid = product_id

    var d = await axios
      .get(
        `${process.env.GATSBY_LACTED_URL}/wp-json/iable/v1/access/?uid=${user.id}&pid=${pid}`
      )
      .catch(err => setError(err))

    var {
      data: {
        data: { response: hasAccess },
        message,
      },
    } = d

    if (!hasAccess) {
      setError(message)
      setLink(true)
      // window.location.href =
      //   process.env.GATSBY_LACTED_URL + "/cart/?add-to-cart=" + pid
    }

    setLocalAuth(p => ({
      ...p,
      token,
      user,
      hasAccess,
      hash: genHash(),
    }))
    setLoading(false)
  }
  const handleKeyPress = e => {
    e.persist()
    if (e.key === "Enter") {
      login()
    }
  }

  if (auth?.hasAccess && auth?.user)
    return (
      <Container>
        <div className="message info centered">You're already logged in</div>
      </Container>
    )

  if (!auth?.hasAccess && auth?.user)
    return (
      <Container>
        <Purchase
          product_id={product_id}
          front_end_url={front_end_url}
          heading={form_heading}
          body={form_description}
          error={error}
        />
      </Container>
    )

  return (
    <Container>
      <Box plain={plain}>
        {!plain && <Img fluid={fluid} />}
        <h3>{title}</h3>
        <p>{description}</p>
        {loading && <Spin />}
        {error && <div className="message danger">{error}</div>}
        {Object.entries(state).map(([name, value], idx) => (
          <div className="form-row" key={idx}>
            <label htmlFor={name}>{name}</label>
            <input
              name={name}
              type={idx ? "password" : "text"}
              onKeyPress={handleKeyPress}
              {...{ name, value, key: name, onChange }}
            />
          </div>
        ))}
        <div className="form-row">
          <div />
          <button onClick={login}>Login</button>
        </div>
        {link && (
          <div className="form-row">
            <div />
            <Purchase
              product_id={product_id}
              front_end_url={front_end_url}
              heading={form_heading}
              body={form_description}
              inline
            />
          </div>
        )}
        <div className="form-row">
          <div />
          <div className="sign-up-txt">
            <p>Don’t have an account?</p>
            <a
              className="link"
              href={`${process.env.GATSBY_LACTED_URL}/cart/?add-to-cart=${product_id}&redirect-url=${front_end_url}`}
            >
              {free ? "Sign Up" : "Purchase"}
            </a>
            <a
              className="link"
              href={`${process.env.GATSBY_LACTED_URL}/my-account/lost-password/?redirect-url=${front_end_url}`}
            >
              Lost Password?
            </a>
          </div>
        </div>
      </Box>
    </Container>
  )
}

const PASSWORD = graphql`
  {
    wordpressAcfOptions {
      options {
        free
        product_id
        front_end_url
        locked_out_message {
          form_description
          form_heading
        }
      }
    }

    file(name: { eq: "secure" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  a {
    color: #1a91ff;
    border-bottom: 2px solid #1a91ff;
    -webkit-text-decoration: none;
    text-decoration: none;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    font-size: 0.9rem;
    margin: 8px 10px;
    display: inline-block;
  }
`

const Purchase = ({
  product_id,
  front_end_url,
  heading,
  body,
  inline,
  error,
}) => {
  var [_, setState] = useGlobalState() // eslint-disable-line no-unused-vars
  return (
    <Wrapper className="" style={{ textAlign: "center" }}>
      {!inline && (
        <div className="message danger">
          {error ||
            "Please purchase/renew this product or sign into a valid account"}{" "}
        </div>
      )}
      <a
        href={
          process.env.GATSBY_LACTED_URL +
          "/cart/?add-to-cart=" +
          product_id +
          "&redirect-url=" +
          front_end_url
        }
      >
        Purchase product
      </a>
      {!inline && (
        <Button
          onClick={() =>
            setState(p => ({
              ...p,
              user: null,
              hasAccess: null,
              hash: null,
              token: null,
            }))
          }
        >
          Swap user
        </Button>
      )}
    </Wrapper>
  )
}

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
  padding: 0 30px;
`
const Box = styled.div`
  h3 {
    font-size: 2rem;
    margin: 0;
    text-align: center;
  }
  p {
    text-align: center;
    max-width: 500px;
  }
  border-radius: ${({ plain }) => (plain ? "0" : "6px")};
  box-shadow: ${({ plain }) => (plain ? "0" : "0 1px 3px 0px #929292")};
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 50%;
  }
  .form-row {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    max-width: 400px;
    grid-gap: 30px;
    .sign-up-txt {
      width: 100%;
      text-align: center;
      p {
        margin: 0;
        font-size: 0.9rem;
      }
    }
  }
  label {
    text-transform: capitalize;
    font-weight: 500;
    justify-self: flex-end;
  }
  button {
    background: #3c3c3c;
    color: white;
    border: 0;
    border-radius: 4px;
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;
    display: block;
    transition: all 200ms ease-in-out;
    &:hover {
      background: #222;
    }
  }
  .link {
    color: #1a91ff;
    border-bottom: 2px solid #1a91ff;
    text-decoration: none;
    transition: all 200ms ease-in-out;
    font-size: 0.9rem;
    margin: 8px 10px;
    display: inline-block;
    &:hover {
      border-bottom: 2px solid #2e6ed7;
      color: #2e6ed7;
    }
  }
  input {
    display: block;
    border-radius: 4px;
    padding: 10px 15px;
    width: calc(100% - 30px);
    border: 1px solid grey;
  }
  @media (max-width: 786px) {
    padding: 0;
    .form-row {
      grid-template-columns: 1fr;
      max-width: 100%;
      grid-gap: 5px;
      label {
        justify-self: flex-start;
      }
    }
    .sign-up-txt {
      width: 100%;
      text-align: center;
      p {
        margin-bottom: 10px !important;
      }
    }
  }
`

const Button = styled.button`
  background: #cccccc;
  color: #4a4a4a;
  border: 0;
  border-radius: 4px;
  padding: 10px 15px;
  /* width: 100%; */
  cursor: pointer;
  display: block;
  transition: all 200ms ease-in-out;
  margin: 0 auto;
  margin-top: 20px;
  &:hover {
    background: #222;
    color: #cccccc;
  }
`

export default Login
