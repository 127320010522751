import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Login from "../components/shared/login"
import { accessValid } from "../components/auth/utils"
import { useEffect } from "react"
import { navigate } from "gatsby"
import useGlobalState from "../utils/useGlobalState"

const LoginPage = ({ path, location }) => {
  const [state] = useGlobalState()

  useEffect(() => {
    if (accessValid(state) && state.user)
      navigate(location?.state?.origin || "/")
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      seo={{ title: "Login" }}
      mainHeader={"Login"}
      mainContent={
        <div>
          <Title>Login</Title>
          <Login plain />
        </div>
      }
    />
  )
}
export default LoginPage

const Title = styled.h2`
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  line-height: 1.2;
  margin: 70px 20px 30px 20px;
`
